import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { WorkerService } from "@gci/helpers/worker.service";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { FuseAlertComponent } from "@fuse/components/alert";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-version-update',
    templateUrl: './app-version-update.component.html',
    styleUrls: ['./app-version-update.component.scss'],
    standalone: true,
    imports: [
        TranslocoModule,
        FuseAlertComponent,
        MatIconModule,
        MatButtonModule,
        CommonModule
    ]
})
export class AppVersionUpdateComponent implements OnInit, OnDestroy {
    public isNewAppVersionAvailable: boolean = false;
    public newAppUpdateAvailableSubscription!: Subscription;
    public activeLang!: string;

    constructor(
        private _serviceWorker: WorkerService,
        private _translateService: TranslocoService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        // Subscribe to language changes
        this._translateService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;

            // Update the UI
            this._changeDetectorRef.markForCheck();
        });

        this.registerNewVersionUpdate();

    }

    ngOnDestroy(): void {
        this.newAppUpdateAvailableSubscription?.unsubscribe();
    }

    registerNewVersionUpdate() {
        this.newAppUpdateAvailableSubscription = this._serviceWorker.$isAnyNewUpdateAvailable.subscribe((versionAvailableFlag: boolean) => {
            this.isNewAppVersionAvailable = versionAvailableFlag;
        });
    }

    refreshApp() {
        window.location.reload();
    }
    
}