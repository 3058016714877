import { Injectable, OnDestroy } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { BehaviorSubject, Subscription } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class WorkerService implements OnDestroy {
    public $isAnyNewUpdateAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _serviceSubscriptions: Subscription[] = [];

    constructor(
        private _swUpdate: SwUpdate) {
        this._checkForVersionUpdate();
    }

    ngOnDestroy(): void {
        this._serviceSubscriptions?.forEach(x => x?.unsubscribe());
    }

    private _checkForVersionUpdate() {
        if (!this._swUpdate.isEnabled) {
            console.log('swUpdate is Not Enabled');
            return;
        }

        console.log('Service worker _checkForVersionUpdate running');

        this._serviceSubscriptions.push(
            this._swUpdate.versionUpdates.subscribe(evt => {
                console.log(evt);
                switch(evt.type) {
                    case 'VERSION_DETECTED':
                        console.log(`Downloading new app version ${evt.version.hash}`);
                        break;
                    case 'VERSION_READY':
                        console.log(`Current app version: ${evt.currentVersion.hash}`);
                        console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                        this.$isAnyNewUpdateAvailable.next(true);
                        break;
                    case 'VERSION_INSTALLATION_FAILED':
                        console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                        break;
                    case 'NO_NEW_VERSION_DETECTED':
                        console.log(`No new version detected`);
                        break;
                }
            }),
        );

        this._serviceSubscriptions.push(
            this._swUpdate.unrecoverable.subscribe(evt => {
                console.log('App is in unrecoverable state. Reloading to avoid chunk load issue.');
                //To do, may be prompt the user first, before loading the page
                window.location.reload();
            }),
        );

        this.checkNewVersionUpdate();
    }

    public checkNewVersionUpdate() {
        this._swUpdate.checkForUpdate().then((isNewVersionAvailable) => {
            this.$isAnyNewUpdateAvailable.next(isNewVersionAvailable);
        });
    }
    
}