import { Inject, PLATFORM_ID, ɵɵdefineInjectable, ɵɵinject, Injectable, Injector, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { isPlatformServer } from '@angular/common';
var AngularPageVisibilityStateEnum;
(function (AngularPageVisibilityStateEnum) {
  AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum["VISIBLE"] = 0] = "VISIBLE";
  AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum["HIDDEN"] = 1] = "HIDDEN";
  AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum["PRERENDER"] = 2] = "PRERENDER";
  AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum["UNLOADED"] = 3] = "UNLOADED";
})(AngularPageVisibilityStateEnum || (AngularPageVisibilityStateEnum = {}));
class HiddenKeyConstant {}
HiddenKeyConstant.DEFAULT = "hidden";
HiddenKeyConstant.MS = "msHidden";
HiddenKeyConstant.WEB_KIT = "webkitHidden";
class VisibilityStatusConstant {}
VisibilityStatusConstant.VISIBLE = "visible";
VisibilityStatusConstant.HIDDEN = "hidden";
VisibilityStatusConstant.PRERENDER = "prerender";
VisibilityStatusConstant.UNLOADED = "unloaded";
class AngularPageVisibilityService {
  constructor(platformId) {
    this.platformId = platformId;
    this.onPageVisibleSource = new Subject();
    this.onPageHiddenSource = new Subject();
    this.onPagePrerenderSource = new Subject();
    this.onPageUnloadedSource = new Subject();
    this.onPageVisibilityChangeSource = new Subject();
    this.$onPageVisible = this.onPageVisibleSource.asObservable();
    this.$onPageHidden = this.onPageHiddenSource.asObservable();
    this.$onPagePrerender = this.onPagePrerenderSource.asObservable();
    this.$onPageUnloaded = this.onPageUnloadedSource.asObservable();
    this.$onPageVisibilityChange = this.onPageVisibilityChangeSource.asObservable();
    this.addEventListenerVibilityChange();
  }
  isPageVisible() {
    return VisibilityStatusConstant.VISIBLE === this.getVisibilityState() || !this.isHidden();
  }
  isPageHidden() {
    return VisibilityStatusConstant.HIDDEN === this.getVisibilityState() || this.isHidden();
  }
  isPagePrerender() {
    return VisibilityStatusConstant.PRERENDER === this.getVisibilityState();
  }
  isPageUnloaded() {
    return VisibilityStatusConstant.UNLOADED === this.getVisibilityState();
  }
  isHidden() {
    return document[this.hidden];
  }
  getVisibilityState() {
    return document[this.visibilityState];
  }
  defineBrowserSupport() {
    if (typeof document[HiddenKeyConstant.DEFAULT] !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      this.hidden = HiddenKeyConstant.DEFAULT;
      this.visibilityChange = "visibilitychange";
      this.visibilityState = "visibilityState";
    } else if (typeof document[HiddenKeyConstant.MS] !== "undefined") {
      this.hidden = HiddenKeyConstant.MS;
      this.visibilityChange = "msvisibilitychange";
      this.visibilityState = "msVisibilityState";
    } else if (typeof document[HiddenKeyConstant.WEB_KIT] !== "undefined") {
      this.hidden = HiddenKeyConstant.WEB_KIT;
      this.visibilityChange = "webkitvisibilitychange";
      this.visibilityState = "webkitVisibilityState";
    }
  }
  addEventListenerVibilityChange() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.defineBrowserSupport();
    document.addEventListener(this.visibilityChange, () => {
      const vibilityState = this.getVisibilityState();
      switch (vibilityState) {
        case VisibilityStatusConstant.VISIBLE:
          this.onPageVisibilityChangeSource.next(AngularPageVisibilityStateEnum.VISIBLE);
          this.onPageVisibleSource.next();
          break;
        case VisibilityStatusConstant.HIDDEN:
          this.onPageVisibilityChangeSource.next(AngularPageVisibilityStateEnum.HIDDEN);
          this.onPageHiddenSource.next();
          break;
        case VisibilityStatusConstant.PRERENDER:
          this.onPageVisibilityChangeSource.next(AngularPageVisibilityStateEnum.PRERENDER);
          this.onPagePrerenderSource.next();
          break;
        case VisibilityStatusConstant.UNLOADED:
          this.onPageVisibilityChangeSource.next(AngularPageVisibilityStateEnum.UNLOADED);
          this.onPageUnloadedSource.next();
          break;
        default:
          if (this.isHidden()) {
            this.onPageVisibilityChangeSource.next(AngularPageVisibilityStateEnum.HIDDEN);
            this.onPageHiddenSource.next();
          } else {
            this.onPageVisibilityChangeSource.next(AngularPageVisibilityStateEnum.VISIBLE);
            this.onPageVisibleSource.next();
          }
      }
    }, false);
  }
}
AngularPageVisibilityService.ctorParameters = () => [{
  type: Object,
  decorators: [{
    type: Inject,
    args: [PLATFORM_ID]
  }]
}];
AngularPageVisibilityService.ɵprov = ɵɵdefineInjectable({
  factory: function AngularPageVisibilityService_Factory() {
    return new AngularPageVisibilityService(ɵɵinject(PLATFORM_ID));
  },
  token: AngularPageVisibilityService,
  providedIn: "root"
});
AngularPageVisibilityService.decorators = [{
  type: Injectable,
  args: [{
    providedIn: "root"
  }]
}];
AngularPageVisibilityService.ctorParameters = () => [{
  type: Object,
  decorators: [{
    type: Inject,
    args: [PLATFORM_ID]
  }]
}];
const providers = [{
  provide: AngularPageVisibilityService,
  deps: []
}];
const injector = Injector.create({
  providers: providers
});
const pageVisibilityService = injector.get(AngularPageVisibilityService);
function OnPageVisibilityChange() {
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    let onPageHiddenSubscription;
    if (!target.ngOnInit) {
      buildNewMethodNgOnInit(target);
    }
    const originalNgOnInit = target.ngOnInit;
    target.ngOnInit = function (...args) {
      onPageHiddenSubscription = pageVisibilityService.$onPageVisibilityChange.subscribe(visibilityState => originalMethod.call(this, [visibilityState]));
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      buildNewMethodNgOnDestroy(target);
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args) {
      onPageHiddenSubscription.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}
function OnPageHidden() {
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    let onPageHiddenSubscription;
    if (!target.ngOnInit) {
      buildNewMethodNgOnInit(target);
    }
    const originalNgOnInit = target.ngOnInit;
    target.ngOnInit = function (...args) {
      onPageHiddenSubscription = pageVisibilityService.$onPageHidden.subscribe(() => originalMethod.call(this));
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      buildNewMethodNgOnDestroy(target);
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args) {
      onPageHiddenSubscription.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}
function OnPageVisible() {
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    if (!target.ngOnInit) {
      buildNewMethodNgOnInit(target);
    }
    const originalNgOnInit = target.ngOnInit;
    let onPageVisibleSubscription;
    target.ngOnInit = function (...args) {
      onPageVisibleSubscription = pageVisibilityService.$onPageVisible.subscribe(() => originalMethod.call(this));
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      buildNewMethodNgOnDestroy(target);
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args) {
      onPageVisibleSubscription.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}
function OnPagePrerender() {
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    let onPagePrerenderSubscription;
    if (!target.ngOnInit) {
      buildNewMethodNgOnInit(target);
    }
    const originalNgOnInit = target.ngOnInit;
    target.ngOnInit = function (...args) {
      onPagePrerenderSubscription = pageVisibilityService.$onPagePrerender.subscribe(() => originalMethod.call(this));
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      buildNewMethodNgOnDestroy(target);
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args) {
      onPagePrerenderSubscription.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}
function OnPageUnloaded() {
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    let onPageUnloadedSubscription;
    if (!target.ngOnInit) {
      buildNewMethodNgOnInit(target);
    }
    const originalNgOnInit = target.ngOnInit;
    target.ngOnInit = function (...args) {
      onPageUnloadedSubscription = pageVisibilityService.$onPageUnloaded.subscribe(() => originalMethod.call(this));
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      buildNewMethodNgOnDestroy(target);
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args) {
      onPageUnloadedSubscription.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}
function buildNewMethodNgOnInit(target) {
  newMethod(target, 'ngOnInit');
}
function buildNewMethodNgOnDestroy(target) {
  newMethod(target, 'ngOnDestroy');
}
function newMethod(target, name) {
  Object.defineProperty(target, name, {
    value: function (...args) {},
    writable: true
  });
}
class AngularPageVisibilityModule {}
AngularPageVisibilityModule.decorators = [{
  type: NgModule,
  args: [{
    imports: [],
    declarations: [],
    providers: [AngularPageVisibilityService],
    exports: []
  }]
}];

/*
 * Public API Surface of angular-page-visibility-lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularPageVisibilityModule, AngularPageVisibilityService, AngularPageVisibilityStateEnum, OnPageHidden, OnPagePrerender, OnPageUnloaded, OnPageVisibilityChange, OnPageVisible };
