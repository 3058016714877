<div class="p-2" *ngIf="isNewAppVersionAvailable">
    <fuse-alert 
    [appearance]="'outline'"
    [type]="'info'">
        <span fuseAlertTitle>{{'New App Version' | transloco}}</span>
        {{'A new version of the app is available. Click on the refresh button to update.' | transloco}}
        <button class="mr-3" mat-flat-button [color]="'primary'" (click)="refreshApp()">
            <mat-icon class="pr-2" [svgIcon]="'mat_outline:refresh'"></mat-icon>
            {{'Refresh' | transloco}}
        </button>
    </fuse-alert>
</div>
