/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_solide:home',
        link: 'home'
    },
    {
        id: 'product',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: [
            {
                id: 'egate',
                title: 'EGate',
                type: 'basic',
                icon: 'heroicons_solid:radio',
                link: 'product/egate',
            },
            {
                id: 'evgate',
                title: 'EVGate',
                type: 'basic',
                icon: 'mat_solid:electric_car',
                link: 'product/evgate',
            },
            {
                id: 'teletoken',
                title: 'Teletoken',
                type: 'basic',
                icon: 'mat_solid:generating_tokens',
                link: 'product/teletoken',
            }
        ],
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: [
            {
                id: 'pju',
                title: 'PJU',
                type: 'basic',
                icon: 'heroicons_solid:light-bulb',
                link: 'solution/pju',
            },
            {
                id: 'tower',
                title: 'Tower',
                type: 'basic',
                icon: 'heroicons_solid:building-office',
                link: 'solution/tower',
            },
            {
                id: 'homecharging',
                title: 'Home Charging',
                type: 'basic',
                icon: 'mat_solid:ev_station',
                link: 'solution/homecharging',
            },
        ],
    },
    {
        id: 'spacer',
        title: 'spacer',
        type: 'spacer',
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_solid:document-text',
        externalLink: true,
        link: 'https://google.com'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_solid:home',
        link: 'home'
    },
    {
        id: 'product',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: [],
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: [],
    },
    {
        id: 'spacer',
        title: 'spacer',
        type: 'spacer',
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_solid:document-text',
        externalLink: true,
        link: 'https://google.com'
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_solid:home',
        link: 'home'
    },
    {
        id: 'product',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: [],
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: [],
    },
    {
        id: 'spacer',
        title: 'spacer',
        type: 'spacer',
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_solid:document-text',
        externalLink: true,
        link: 'https://google.com'
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_solid:home',
        link: 'home'
    },
    {
        id: 'product',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: [],
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: [],
    },
    {
        id: 'spacer',
        title: 'spacer',
        type: 'spacer',
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_solid:document-text',
        externalLink: true,
        link: 'https://google.com'
    },
];
