import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Logger } from '@gci/helpers/logger';
import { WorkerService } from '@gci/helpers/worker.service';
import {
    OnPageVisible, OnPageHidden,
    OnPageVisibilityChange,
    AngularPageVisibilityStateEnum,
    OnPagePrerender, OnPageUnloaded} from 'angular-page-visibility';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    private _title = 'gci-fuse';
    private _logger = new Logger('AppComponent');

    /**
     * Constructor
     */
    constructor(
        private _serviceWorker: WorkerService, 
        // private _websocketService: WebsocketService,
    )
    {
        this._serviceWorker.$isAnyNewUpdateAvailable.subscribe((versionAvailableFlag: boolean) => {
            this._logger.debug("app.component versionAvailable", versionAvailableFlag);
        });    
    }

    @OnPageVisible()
    logWhenPageVisible (): void {
        this._logger.debug( 'OnPageVisible => visible' );
    
        // this._websocketService.onPageVisible();
        this._serviceWorker.checkNewVersionUpdate();
    }

    @OnPageHidden()
    logWhenPageHidden (): void {
        this._logger.debug( 'OnPageHidden => hidden' );
        // this._websocketService.onPageHidden();
    }

    @OnPagePrerender()
    logWhenPagePrerender (): void {
        this._logger.debug( 'OnPagePrerender => prerender' );
    }

    @OnPageUnloaded()
    logWhenPageUnloaded (): void {
        this._logger.debug( 'OnPageUnloaded => unloaded' );
    }

    @OnPageVisibilityChange()
    logWhenPageVisibilityChange ( visibilityState: AngularPageVisibilityStateEnum ): void {
        if ( AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE]) {
            this._logger.debug( 'OnPageVisibilityChange => visible' );
        } 
        else if (AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.HIDDEN]) {
            this._logger.debug( 'OnPageVisibilityChange => hidden' );
        } 
        else if (AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.PRERENDER]) {
            this._logger.debug( 'OnPageVisibilityChange => prerender' );
        } 
        else if (AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.UNLOADED]) {
            this._logger.debug( 'OnPageVisibilityChange => unloaded' );
        }
    }
}
